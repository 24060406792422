window.$ = document.querySelector.bind(document);
window.$$ = document.querySelectorAll.bind(document);

$("#mobileMenu").addEventListener("click", () => {
  $("#menu").classList.toggle("open");
});

window.addEventListener("resize", () => {
  $("#menu").classList.remove("open");
});

// Poll for gtag
function waitForGtag(callback) {
  const interval = setInterval(() => {
    if (typeof window.gtag === "function") {
      clearInterval(interval); // Stop polling
      callback(); // Execute your callback function
    }
  }, 200); // Check every 200ms
}

// Approve
function approveGoogle() {
  waitForGtag(() => {
	window.gtag("consent", "update", {
		ad_user_data: "granted",
		ad_personalization: "granted",
		ad_storage: "granted",
		analytics_storage: "granted",
	});
  });
}

// Google defailt
waitForGtag(() => {
  window.gtag("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
  });
});

const acceptCookieBtn = $("#acceptCookies");
if (acceptCookieBtn) {
  acceptCookieBtn.addEventListener("click", () => {
    $(".cookie-wall").classList.add("hidden");
    localStorage.setItem("cookiesAccepted", true);
	approveGoogle();
  });
}

// if cookies are accepted, remove
if (!localStorage.getItem("cookiesAccepted")) {
  $(".cookie-wall").classList.remove("hidden");
  approveGoogle();
}
